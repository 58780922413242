import React, { useState, useEffect } from "react";

function ActiveAccordion({ children, title, size }) {
  const [setActive, setActiveState] = useState(true);

  function toggleAccordion() {
    setActiveState(setActive === false ? true : false);
  }
  return (
    <div
      class={
        size == "small"
          ? "osce-station-page-cards-small"
          : "osce-station-page-cards"
      }
    >
      <h1
        class={
          size == "small" ? "heading-2-small pointer" : "heading-2 pointer"
        }
        onClick={toggleAccordion}
      >
        {title}
      </h1>
      <div
        class={setActive ? "instructions-content-show" : "instructions-content"}
      >
        {children}
      </div>
    </div>
  );
}

export default ActiveAccordion;
