import React from "react";
import styled from "styled-components";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Box from "@mui/material/Box";
import Linear from "../../components/Progressbar/Linear";

function Analysis({
  access,
  delayedChecks,
  totalItems,
  totalChecks,
  userShortId,
  handleInputChange,
  peerShortId,
  submitPeerStation,
  submitStation,
}) {
  const ProgressbarWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80%;
    flex-wrap: wrap;

    @media (max-width: 1000px) {
      flex-direction: column;
    }
  `;

  const ProgressbarInnerWrapper = styled.div`
    max-width: 50%;
    align-self: center;
    margin: 40px;
    margin-left: 20px;

    @media (max-width: 1000px) {
      margin: 20px;
    }
  `;

  return (
    <>
      <ProgressbarWrapper>
        <ProgressbarInnerWrapper>
          <div class="total-score">Total Score</div>
          <CircularProgressbar
            value={access ? (100 * delayedChecks) / totalItems.current : 0}
            text={
              access
                ? `${Math.round((delayedChecks / totalItems.current) * 100)}%`
                : "0%"
            }
            styles={buildStyles({
              textColor: "#1e173f",
              pathColor: "#1e173f",
              trailColor: "#C4C2DA",
            })}
          />
        </ProgressbarInnerWrapper>
        {access && (
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              margin: "5px",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {" "}
            {totalChecks.map((object) => {
              if (isNaN(Math.round(object.value))) {
                return;
              }
              return <Linear title={object.title} value={object.value} />;
            })}
          </Box>
        )}
      </ProgressbarWrapper>
      <div class="submit-results--div">
        <div class="submit-score-div">
          <div class="text-block-12">Submit Score</div>
          <div class="text-block-14">
            PERSONAL CODE: <span class="text-span-14">{userShortId}</span>
          </div>
        </div>
        <div class="text-block-13">
          Track your results by saving your score or submitting them to your
          OSCE peers.
        </div>
        <div class="form-block-3 w-form">
          <form
            id="wf-form-Personal-Code"
            name="wf-form-Personal-Code"
            data-name="Personal Code"
            method="get"
            class="form-3"
          >
            <input
              type="text"
              class="text-field-5 w-input"
              maxlength="256"
              name="name"
              data-name="Name"
              placeholder="YOUR PEER&#x27;S CODE"
              id="name"
              onChange={handleInputChange}
              value={peerShortId}
            />
            <a class="submit-button w-button" onClick={submitPeerStation}>
              Submit Score to Peer
            </a>
            <div class="text-block-15">OR</div>
            <a class="submit-button white w-button" onClick={submitStation}>
              Save to Personal Dashboard
            </a>
          </form>
          <div class="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div class="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Analysis;
