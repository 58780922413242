import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import Linear from "../Progressbar/Linear";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BreakdownButton = styled.button`
  background-color: ${(props) =>
    props.percentage >= 75
      ? "#d7eccd"
      : props.percentage >= 50
      ? "#feedb4"
      : "#fddcd4"};
  color: ${(props) =>
    props.percentage >= 75
      ? "#32940d"
      : props.percentage >= 50
      ? "#f77621"
      : "#f71d02"};
  border-radius: 5px;
`;

export default function BasicModal({ cell }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const breakdownList = cell.row.original.breakdown;
  return (
    <div>
      <BreakdownButton
        percentage={cell.row.values.percentage}
        onClick={handleOpen}
      >
        {`${cell.row.values.percentage}% (breakdown)`}
      </BreakdownButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {breakdownList.map((object) => {
            if (isNaN(Math.round(object.value))) {
              return;
            }
            return <Linear title={object.title} value={object.value} />;
          })}
        </Box>
      </Modal>
    </div>
  );
}
