import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../layout/main";
import styled from "styled-components";
import SimpleAccordion from "../components/Accordion/old";
import { SidebarObject } from "../components/Sidebar";
import Header from "../components/Header";
import Accordion from "../components/Accordion";
import Markdown, { compiler } from "markdown-to-jsx";
import SectionsObject from "../helpers/sections";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FirebaseContext } from "../components/Firebase";
import StationLock from "../components/StationLock";
import SEO from "../components/seo";
import { styled as muiStyled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Container, Popover } from "@mui/material";
import Typography from "@mui/material/Typography";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Box from "@mui/material/Box";
import AudioPlayer from "react-h5-audio-player";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import ActiveAccordion from "../components/Accordion/active";
import Linear from "../components/Progressbar/Linear";
import Analysis from "../containers/Station/Analysis";
import Alert from "@mui/material/Alert";
import ThinLoadingWall from "../components/ThinLoadingWall";
import ThickLoadingWall from "../components/ThickLoadingWall";
import TableComponent from "../components/Table/StationTable";
import PDFButton from "../components/PDFButton";
import Usps from "../containers/Home/Usps";

const H3Wrapper = styled.div`
  margin-bottom: 0px;
`;

const AnalysisWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
`;

const Line = styled.div`
  height: 0px;
  background: black;
  margin-bottom: 15px;
`;

const ActorWrapper = styled.div`
  user-select: none;
`;

const ChecklistWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 100px;
  margin-top: 60px;
`;

const ProgressbarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  flex-wrap: wrap;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const OptionalText = styled.div`
  margin: 10px;
  padding: 40px 0px 0px 10px;
  color: #1e173f;
`;

const ProgressbarInnerWrapper = styled.div`
  max-width: 50%;
  align-self: center;
  margin: 40px;
  margin-left: 20px;

  @media (max-width: 1000px) {
    margin: 20px;
  }
`;

const ImageWrapper = styled.img`
  max-width: 100%;
`;

const StationTemplateNew = ({ data }) => {
  //strapi data
  const actor = data.strapiStations.actor_instruction;
  const candidate = data.strapiStations.candidate_instruction;
  const markscheme = data.strapiStations.markscheme;
  const potentialPDF = data.allFile.edges;
  const additional_resources =
    data.strapiStations.additional_resources.concat(potentialPDF);
  const overriding_markscheme = data.strapiStations.overriding_markscheme;
  const description = data.strapiStations.description;
  const optionalText = data.strapiStations.Optional_text;
  const actorCompiled = compiler(actor);
  const actorItems = actorCompiled.props.children;
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const checks = useRef(0);
  const totalItems = useRef(0);
  const [delayedChecks, setDelayedChecks] = useState(0);
  const { user, firebase, loading, serverTime } = useContext(FirebaseContext);
  const [access, setAccess] = useState(false);
  const [lightbox, setLightbox] = useState("");
  const [tickedPoints, setTickedPoints] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [analysed, setAnalysed] = useState(false);
  const [pageLogged, setPageLogged] = useState(false);
  const [userShortId, setUserShortId] = useState("");
  const [peerShortId, setPeerShortId] = useState("");
  const [internalLoading, setInternalLoading] = useState(false);
  const [scoreSubmitSuccess, setScoreSubmitSuccess] = useState(null);
  const [scoreSubmitMessage, setScoreSubmitMessage] = useState("");
  const [stationAttempts, setStationAttempts] = useState([]);
  const [subscriber_only, setSubscriberOnly] = useState(false);
  const [university, setUniversity] = useState("");
  useEffect(() => {
    if (user) {
      const userUni = user.university;
      if (userUni && userUni.name) {
        setUniversity(userUni.name);
      }
    }
  }, [user]);
  const analyse = () => {
    setAnalysed(true);
    setDisabled(true);
    setTimeout(() => {
      setDelayedChecks(checks.current);
    }, 100);
    overriding_markscheme.block.forEach((section) => {
      const convertedPoints = convertPoints(
        section.Pass_threshold,
        section.Excellent_threshold,
        calcSectionPoints(section)
      );
      updateSectionCheck(section.strapi_component, convertedPoints);
      updateTotalSectionCheck(section.strapi_component);
      updateCheck(convertedPoints);
      updateTotalCheck();
    });

    // submitStation();
  };

  // this function calculates the number of boxes checked in a section
  const calcSectionPoints = (section) => {
    var count = 0;
    section.points.forEach((point) => {
      if (tickedPoints.includes(point.id)) {
        count += 1;
      }
    });
    console.log(count);
    return count;
  };

  // this function takes the number of raw points and converts into final points based on pass and excellent thresholds
  const convertPoints = (pass, excellent, points) => {
    if (points >= excellent) {
      return 2;
    } else if (points >= pass) {
      return 1 + (points - pass) / (excellent - pass);
    } else {
      return points / pass;
    }
  };

  const checkboxClicked = (id) => {
    if (tickedPoints.includes(id)) {
      setTickedPoints(tickedPoints.filter((item) => item != id));
    } else {
      setTickedPoints([...tickedPoints, id]);
    }
  };

  // These keep track of the number of checked boxes in each section
  const sectionChecks = useRef({
    intro: 0,
    pc: 0,
    hpc: 0,
    pmh: 0,
    dh: 0,
    obsth: 0,
    sexh: 0,
    fh: 0,
    sh: 0,
    systems: 0,
    ice: 0,
    conc: 0,
    dx: 0,
    ix: 0,
    tx: 0,
    qx: 0,
    danger: 0,
    response: 0,
    callHelp: 0,
    basicObs: 0,
    airway: 0,
    breathing: 0,
    circulation: 0,
    disability: 0,
    exposure: 0,
    CN1: 0,
    CN2: 0,
    CN346: 0,
    CN5: 0,
    CN7: 0,
    CN8: 0,
    CN91012: 0,
    CN11: 0,
    tone: 0,
    power: 0,
    coordination: 0,
    reflexes: 0,
    sensation: 0,
    specialTests: 0,
    hands: 0,
    face: 0,
    neck: 0,
    inspection: 0,
    palpation: 0,
    percussion: 0,
    auscultation: 0,
    presentation: 0,
    findings: 0,
    gait: 0,
    function: 0,
    ankles: 0,
    chest: 0,
    legs: 0,
    back: 0,
    speculum: 0,
    groin: 0,
    abdomen: 0,
    when: 0,
    why: 0,
    benefits: 0,
    before: 0,
    how: 0,
    other: 0,
    during: 0,
    what: 0,
    contraindications: 0,
    after: 0,
    prognosis: 0,
    risks: 0,
    description: 0,
  });
  // These are a count of the total boxes in each section
  const totalChecksTracker = useRef({
    totalIntroChecks: 0,
    totalPcChecks: 0,
    totalHpcChecks: 0,
    totalPmhChecks: 0,
    totalDhChecks: 0,
    totalObsthChecks: 0,
    totalSexhChecks: 0,
    totalFhChecks: 0,
    totalShChecks: 0,
    totalSystemsChecks: 0,
    totalIceChecks: 0,
    totalConcChecks: 0,
    totalDxChecks: 0,
    totalIxChecks: 0,
    totalTxChecks: 0,
    totalQxChecks: 0,
    totalDangerChecks: 0,
    totalResponseChecks: 0,
    totalCallHelpChecks: 0,
    totalBasicObsChecks: 0,
    totalAirwayChecks: 0,
    totalBreathingChecks: 0,
    totalCirculationChecks: 0,
    totalDisabilityChecks: 0,
    totalExposureChecks: 0,
    totalCN1Checks: 0,
    totalCN2Checks: 0,
    totalCN346Checks: 0,
    totalCN5Checks: 0,
    totalCN7Checks: 0,
    totalCN8Checks: 0,
    totalCN91012Checks: 0,
    totalCN11Checks: 0,
    totalToneChecks: 0,
    totalPowerChecks: 0,
    totalCoordinationChecks: 0,
    totalReflexesChecks: 0,
    totalSensationChecks: 0,
    totalSpecialTestsChecks: 0,
    totalHandsChecks: 0,
    totalFaceChecks: 0,
    totalNeckChecks: 0,
    totalInspectionChecks: 0,
    totalPalpationChecks: 0,
    totalPercussionChecks: 0,
    totalAuscultationChecks: 0,
    totalPresentationChecks: 0,
    totalFindingsChecks: 0,
    totalGaitChecks: 0,
    totalFunctionChecks: 0,
    totalAnklesChecks: 0,
    totalChestChecks: 0,
    totalLegsChecks: 0,
    totalBackChecks: 0,
    totalSpeculumChecks: 0,
    totalGroinChecks: 0,
    totalAbdomenChecks: 0,
    totalWhenChecks: 0,
    totalWhyChecks: 0,
    totalBenefitsChecks: 0,
    totalBeforeChecks: 0,
    totalHowChecks: 0,
    totalOtherChecks: 0,
    totalDuringChecks: 0,
    totalWhatChecks: 0,
    totalContraindicationsChecks: 0,
    totalAfterChecks: 0,
    totalPrognosisChecks: 0,
    totalRisksChecks: 0,
    totalDescriptionChecks: 0,
  });
  // These are the proportion checked boxes
  const totalChecks = [
    {
      title: "Intro",
      value:
        sectionChecks.current.intro /
        totalChecksTracker.current.totalIntroChecks,
    },
    {
      title: "Danger",
      value:
        sectionChecks.current.danger /
        totalChecksTracker.current.totalDangerChecks,
    },
    {
      title: "Response",
      value:
        sectionChecks.current.response /
        totalChecksTracker.current.totalResponseChecks,
    },
    {
      title: "Call for help",
      value:
        sectionChecks.current.callHelp /
        totalChecksTracker.current.totalCallHelpChecks,
    },
    {
      title: "Basic Obs",
      value:
        sectionChecks.current.basicObs /
        totalChecksTracker.current.totalBasicObsChecks,
    },
    {
      title: "Airway",
      value:
        sectionChecks.current.airway /
        totalChecksTracker.current.totalAirwayChecks,
    },
    {
      title: "Breathing",
      value:
        sectionChecks.current.breathing /
        totalChecksTracker.current.totalBreathingChecks,
    },
    {
      title: "Circulation",
      value:
        sectionChecks.current.circulation /
        totalChecksTracker.current.totalCirculationChecks,
    },
    {
      title: "Disability",
      value:
        sectionChecks.current.disability /
        totalChecksTracker.current.totalDisabilityChecks,
    },
    {
      title: "Exposure",
      value:
        sectionChecks.current.exposure /
        totalChecksTracker.current.totalExposureChecks,
    },

    {
      title: "PC",
      value:
        sectionChecks.current.pc / totalChecksTracker.current.totalPcChecks,
    },
    {
      title: "HPC",
      value:
        sectionChecks.current.hpc / totalChecksTracker.current.totalHpcChecks,
    },
    {
      title: "PMHx",
      value:
        sectionChecks.current.pmh / totalChecksTracker.current.totalPmhChecks,
    },
    {
      title: "DHx",
      value:
        sectionChecks.current.dh / totalChecksTracker.current.totalDhChecks,
    },
    {
      title: "O&G Hx",
      value:
        sectionChecks.current.obsth /
        totalChecksTracker.current.totalObsthChecks,
    },
    {
      title: "SexHx",
      value:
        sectionChecks.current.sexh / totalChecksTracker.current.totalSexhChecks,
    },
    {
      title: "FHx",
      value:
        sectionChecks.current.fh / totalChecksTracker.current.totalFhChecks,
    },
    {
      title: "SHx",
      value:
        sectionChecks.current.sh / totalChecksTracker.current.totalShChecks,
    },
    {
      title: "Systems",
      value:
        sectionChecks.current.systems /
        totalChecksTracker.current.totalSystemsChecks,
    },
    {
      title: "ICE",
      value:
        sectionChecks.current.ice / totalChecksTracker.current.totalIceChecks,
    },
    {
      title: "Inspection",
      value:
        sectionChecks.current.inspection /
        totalChecksTracker.current.totalInspectionChecks,
    },
    {
      title: "CN 1",
      value:
        sectionChecks.current.CN1 / totalChecksTracker.current.totalCN1Checks,
    },
    {
      title: "CN 2",
      value:
        sectionChecks.current.CN2 / totalChecksTracker.current.totalCN2Checks,
    },
    {
      title: "CN 3, 4, 6",
      value:
        sectionChecks.current.CN346 /
        totalChecksTracker.current.totalCN346Checks,
    },
    {
      title: "CN 5",
      value:
        sectionChecks.current.CN5 / totalChecksTracker.current.totalCN5Checks,
    },
    {
      title: "CN 7",
      value:
        sectionChecks.current.CN7 / totalChecksTracker.current.totalCN7Checks,
    },
    {
      title: "CN 8",
      value:
        sectionChecks.current.CN8 / totalChecksTracker.current.totalCN8Checks,
    },
    {
      title: "CN 9, 10, 12",
      value:
        sectionChecks.current.CN91012 /
        totalChecksTracker.current.totalCN91012Checks,
    },
    {
      title: "CN 11",
      value:
        sectionChecks.current.CN11 /
        totalChecksTracker.current.totalCN91012Checks,
    },
    {
      title: "Gait",
      value:
        sectionChecks.current.gait / totalChecksTracker.current.totalGaitChecks,
    },
    {
      title: "Function",
      value:
        sectionChecks.current.function /
        totalChecksTracker.current.totalFunctionChecks,
    },

    {
      title: "Tone",
      value:
        sectionChecks.current.tone / totalChecksTracker.current.totalToneChecks,
    },
    {
      title: "Power",
      value:
        sectionChecks.current.power /
        totalChecksTracker.current.totalPowerChecks,
    },
    {
      title: "Coordination",
      value:
        sectionChecks.current.coordination /
        totalChecksTracker.current.totalCoordinationChecks,
    },
    {
      title: "Reflexes",
      value:
        sectionChecks.current.reflexes /
        totalChecksTracker.current.totalReflexesChecks,
    },
    {
      title: "Sensation",
      value:
        sectionChecks.current.sensation /
        totalChecksTracker.current.totalSensationChecks,
    },
    {
      title: "Special tests",
      value:
        sectionChecks.current.specialTests /
        totalChecksTracker.current.totalSpecialTestsChecks,
    },
    {
      title: "Hands",
      value:
        sectionChecks.current.hands /
        totalChecksTracker.current.totalHandsChecks,
    },
    {
      title: "Face",
      value:
        sectionChecks.current.face / totalChecksTracker.current.totalFaceChecks,
    },
    {
      title: "Neck",
      value:
        sectionChecks.current.neck / totalChecksTracker.current.totalNeckChecks,
    },
    {
      title: "Chest",
      value:
        sectionChecks.current.chest /
        totalChecksTracker.current.totalChestChecks,
    },
    {
      title: "Abdomen",
      value:
        sectionChecks.current.abdomen /
        totalChecksTracker.current.totalAbdomenChecks,
    },
    {
      title: "Back",
      value:
        sectionChecks.current.back / totalChecksTracker.current.totalBackChecks,
    },
    {
      title: "Groin",
      value:
        sectionChecks.current.groin /
        totalChecksTracker.current.totalGroinChecks,
    },
    {
      title: "Legs",
      value:
        sectionChecks.current.legs / totalChecksTracker.current.totalLegsChecks,
    },
    {
      title: "Ankles",
      value:
        sectionChecks.current.ankles /
        totalChecksTracker.current.totalAnklesChecks,
    },

    {
      title: "Palpation",
      value:
        sectionChecks.current.palpation /
        totalChecksTracker.current.totalPalpationChecks,
    },
    {
      title: "Percussion",
      value:
        sectionChecks.current.percussion /
        totalChecksTracker.current.totalPercussionChecks,
    },
    {
      title: "Auscultation",
      value:
        sectionChecks.current.auscultation /
        totalChecksTracker.current.totalAuscultationChecks,
    },
    {
      title: "Speculum",
      value:
        sectionChecks.current.speculum /
        totalChecksTracker.current.totalSpeculumChecks,
    },
    {
      title: "When",
      value:
        sectionChecks.current.when / totalChecksTracker.current.totalWhenChecks,
    },
    {
      title: "Why",
      value:
        sectionChecks.current.why / totalChecksTracker.current.totalWhyChecks,
    },
    {
      title: "Benefits",
      value:
        sectionChecks.current.benefits /
        totalChecksTracker.current.totalBenefitsChecks,
    },
    {
      title: "Before",
      value:
        sectionChecks.current.before /
        totalChecksTracker.current.totalBeforeChecks,
    },
    {
      title: "How",
      value:
        sectionChecks.current.how / totalChecksTracker.current.totalHowChecks,
    },
    {
      title: "Other",
      value:
        sectionChecks.current.other /
        totalChecksTracker.current.totalOtherChecks,
    },
    {
      title: "During",
      value:
        sectionChecks.current.during /
        totalChecksTracker.current.totalDuringChecks,
    },
    {
      title: "What",
      value:
        sectionChecks.current.what / totalChecksTracker.current.totalWhatChecks,
    },
    {
      title: "Contraindications",
      value:
        sectionChecks.current.contraindications /
        totalChecksTracker.current.totalContraindicationsChecks,
    },
    {
      title: "After",
      value:
        sectionChecks.current.after /
        totalChecksTracker.current.totalAfterChecks,
    },
    {
      title: "Prognosis",
      value:
        sectionChecks.current.prognosis /
        totalChecksTracker.current.totalPrognosisChecks,
    },
    {
      title: "Risks",
      value:
        sectionChecks.current.risks /
        totalChecksTracker.current.totalRisksChecks,
    },
    {
      title: "Description",
      value:
        sectionChecks.current.description /
        totalChecksTracker.current.totalDescriptionChecks,
    },
    {
      title: "Conclusion",
      value:
        sectionChecks.current.conc / totalChecksTracker.current.totalConcChecks,
    },
    {
      title: "Presentation",
      value:
        sectionChecks.current.presentation /
        totalChecksTracker.current.totalPresentationChecks,
    },

    {
      title: "Findings",
      value:
        sectionChecks.current.findings /
        totalChecksTracker.current.totalFindingsChecks,
    },

    {
      title: "Dx",
      value:
        sectionChecks.current.dx / totalChecksTracker.current.totalDxChecks,
    },
    {
      title: "Ix",
      value:
        sectionChecks.current.ix / totalChecksTracker.current.totalIxChecks,
    },
    {
      title: "Tx",
      value:
        sectionChecks.current.tx / totalChecksTracker.current.totalTxChecks,
    },
    {
      title: "Q&A",
      value:
        sectionChecks.current.qx / totalChecksTracker.current.totalQxChecks,
    },
  ];

  const submitStation = () => {
    setInternalLoading(true);
    firebase
      .submitStation({
        stationId: data.strapiStations.strapiId,
        stationTitle: data.strapiStations.Title,
        stationType: data.strapiStations.type,
        specialty: data.strapiStations.Specialty,
        tickedPoints,
        percentage: Math.round((checks.current / totalItems.current) * 100),
        breakdown: totalChecks.filter(
          (section) => !isNaN(Math.round(section.value))
        ),
      })
      .then((response) => {
        console.log(response);
        setScoreSubmitSuccess(response.data.success);
        setScoreSubmitMessage(response.data.message);
        updateStationAttempts();
        setInternalLoading(false);
      });
  };

  const submitPeerStation = () => {
    setInternalLoading(true);
    firebase
      .submitPeerStation({
        stationId: data.strapiStations.strapiId,
        stationTitle: data.strapiStations.Title,
        stationType: data.strapiStations.type,
        specialty: data.strapiStations.Specialty,
        tickedPoints,
        percentage: Math.round((checks.current / totalItems.current) * 100),
        breakdown: totalChecks.filter(
          (section) => !isNaN(Math.round(section.value))
        ),
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        shortId: peerShortId,
      })
      .then((response) => {
        console.log(response);
        setScoreSubmitSuccess(response.data.success);
        setScoreSubmitMessage(response.data.message);
        updateStationAttempts();
        setInternalLoading(false);
      });
  };

  const CheckForAccess = () => {
    if (data.strapiStations.example) {
      setAccess(true);
      return;
    }
    if (user == null) {
      return;
    }
    if (user.expiryTime.toMillis() > serverTime && user.emailVerified) {
      setAccess(true);
      setUserShortId(user.shortId);
      firebase.logPage({ page_title: data.strapiStations.Title });
    }
    if (
      user.latestPlan == "Free Trial" &&
      data.strapiStations.subscriber_only == true
    ) {
      setSubscriberOnly(true);
      setAccess(false);
    }
  };

  const updateStationAttempts = () => {
    firebase
      .retrieveStationAttempts({
        stationId: data.strapiStations.strapiId,
      })
      .then((response) => {
        console.log(response.data);
        setStationAttempts(response.data);
      });
  };

  useEffect(() => {
    if (!loading) {
      CheckForAccess();
      if (user) {
        updateStationAttempts();
      }
    }
  }, [loading]);

  useEffect(() => {
    console.log(stationAttempts);
  }, [stationAttempts]);

  // this is how the ticked boxes update the count
  const updateSectionCheck = (section, amount) => {
    switch (section) {
      case "all-markschemes.introduction":
        sectionChecks.current.intro += amount;
        break;
      case "history-markschemes.presenting-complaint":
        sectionChecks.current.pc += amount;
        break;
      case "history-markschemes.history-presenting-complaint":
        sectionChecks.current.hpc += amount;
        break;
      case "history-markschemes.past-medical-history":
        sectionChecks.current.pmh += amount;
        break;
      case "history-markschemes.drug-history":
        sectionChecks.current.dh += amount;
        break;
      case "history-markschemes.obstetric-history":
        sectionChecks.current.obsth += amount;
        break;
      case "history-markschemes.sexual-history":
        sectionChecks.current.sexh += amount;
        break;
      case "history-markschemes.family-history":
        sectionChecks.current.fh += amount;
        break;
      case "history-markschemes.social-history":
        sectionChecks.current.sh += amount;
        break;
      case "history-markschemes.systems-review":
        sectionChecks.current.systems += amount;
        break;
      case "history-markschemes.ideas-concerns-expectations":
        sectionChecks.current.ice += amount;
        break;
      case "all-markschemes.conclusion":
        sectionChecks.current.conc += amount;
        break;
      case "all-markschemes.diagnosis":
        sectionChecks.current.dx += amount;
        break;
      case "all-markschemes.investigations":
        sectionChecks.current.ix += amount;
        break;
      case "all-markschemes.management":
        sectionChecks.current.tx += amount;
        break;
      case "all-markschemes.questions":
        sectionChecks.current.qx += amount;
        break;
      case "abcde-markscheme.danger":
        sectionChecks.current.danger += amount;
        break;
      case "abcde-markscheme.response":
        sectionChecks.current.response += amount;
        break;
      case "abcde-markscheme.call_for_help":
        sectionChecks.current.callHelp += amount;
        break;
      case "abcde-markscheme.basic_observations":
        sectionChecks.current.basicObs += amount;
        break;
      case "abcde-markscheme.airway":
        sectionChecks.current.airway += amount;
        break;
      case "abcde-markscheme.breathing":
        sectionChecks.current.breathing += amount;
        break;
      case "abcde-markscheme.circulation":
        sectionChecks.current.circulation += amount;
        break;
      case "abcde-markscheme.disability":
        sectionChecks.current.disability += amount;
        break;
      case "abcde-markscheme.exposure":
        sectionChecks.current.exposure += amount;
        break;
      case "neurological-examination-markschemes.cn-i":
        sectionChecks.current.CN1 += amount;
        break;
      case "neurological-examination-markschemes.cn-ii":
        sectionChecks.current.CN2 += amount;
        break;
      case "neurological-examination-markschemes.cn-iii-iv-vi":
        sectionChecks.current.CN346 += amount;
        break;
      case "neurological-examination-markschemes.cn-v":
        sectionChecks.current.CN5 += amount;
        break;
      case "neurological-examination-markschemes.cn-vii":
        sectionChecks.current.CN7 += amount;
        break;
      case "neurological-examination-markschemes.cn-viii":
        sectionChecks.current.CN8 += amount;
        break;
      case "neurological-examination-markschemes.cn-ix-x-xii":
        sectionChecks.current.CN91012 += amount;
        break;
      case "neurological-examination-markschemes.cn-xi":
        sectionChecks.current.CN11 += amount;
        break;
      case "neurological-examination-markschemes.tone":
        sectionChecks.current.tone += amount;
        break;
      case "neurological-examination-markschemes.power":
        sectionChecks.current.power += amount;
        break;
      case "neurological-examination-markschemes.coordination":
        sectionChecks.current.coordination += amount;
        break;
      case "neurological-examination-markschemes.reflexes":
        sectionChecks.current.reflexes += amount;
        break;
      case "neurological-examination-markschemes.sensation":
        sectionChecks.current.sensation += amount;
        break;
      case "neurological-examination-markschemes.special-tests":
        sectionChecks.current.specialTests += amount;
        break;
      case "examination-general-markschemes.inspection":
        sectionChecks.current.inspection += amount;
        break;
      case "examination-general-markschemes.hands":
        sectionChecks.current.hands += amount;
        break;
      case "examination-general-markschemes.face":
        sectionChecks.current.face += amount;
        break;
      case "examination-general-markschemes.neck":
        sectionChecks.current.neck += amount;
        break;
      case "examination-general-markschemes.palpation":
        sectionChecks.current.palpation += amount;
        break;
      case "examination-general-markschemes.percussion":
        sectionChecks.current.percussion += amount;
        break;
      case "examination-general-markschemes.auscultation":
        sectionChecks.current.auscultation += amount;
        break;
      case "examination-general-markschemes.presentation":
        sectionChecks.current.presentation += amount;
        break;
      case "examination-general-markschemes.findings":
        sectionChecks.current.findings += amount;
        break;
      case "neurological-examination-markschemes.gait":
        sectionChecks.current.gait += amount;
        break;
      case "examination-general-markschemes.function":
        sectionChecks.current.function += amount;
        break;
      case "examination-general-markschemes.ankles":
        sectionChecks.current.ankles += amount;
        break;
      case "examination-general-markschemes.chest":
        sectionChecks.current.chest += amount;
        break;
      case "examination-general-markschemes.legs":
        sectionChecks.current.legs += amount;
        break;
      case "examination-general-markschemes.back":
        sectionChecks.current.back += amount;
        break;
      case "examination-general-markschemes.speculum":
        sectionChecks.current.speculum += amount;
        break;
      case "examination-general-markschemes.groin":
        sectionChecks.current.groin += amount;
        break;
      case "examination-general-markschemes.abdomen":
        sectionChecks.current.abdomen += amount;
        break;
      case "explanation-markscheme.when":
        sectionChecks.current.when += amount;
        break;
      case "explanation-markscheme.why":
        sectionChecks.current.why += amount;
        break;
      case "explanation-markscheme.benefits":
        sectionChecks.current.benefits += amount;
        break;
      case "explanation-markscheme.before":
        sectionChecks.current.before += amount;
        break;
      case "explanation-markscheme.how":
        sectionChecks.current.how += amount;
        break;
      case "explanation-markscheme.other":
        sectionChecks.current.other += amount;
        break;
      case "explanation-markscheme.during":
        sectionChecks.current.during += amount;
        break;
      case "explanation-markscheme.what":
        sectionChecks.current.what += amount;
        break;
      case "explanation-markscheme.contraindications":
        sectionChecks.current.contraindications += amount;
        break;
      case "explanation-markscheme.after":
        sectionChecks.current.after += amount;
        break;
      case "explanation-markscheme.prognosis":
        sectionChecks.current.prognosis += amount;
        break;
      case "explanation-markscheme.risks":
        sectionChecks.current.risks += amount;
        break;
      case "explanation-markscheme.description":
        sectionChecks.current.description += amount;
        break;
      default:
        break;
    }
  };
  // this is how the total number of boxes is counted
  const updateTotalSectionCheck = (section) => {
    switch (section) {
      case "all-markschemes.introduction":
        totalChecksTracker.current.totalIntroChecks += 2;
        break;
      case "history-markschemes.presenting-complaint":
        totalChecksTracker.current.totalPcChecks += 2;
        break;
      case "history-markschemes.history-presenting-complaint":
        totalChecksTracker.current.totalHpcChecks += 2;
        break;
      case "history-markschemes.past-medical-history":
        totalChecksTracker.current.totalPmhChecks += 2;
        break;
      case "history-markschemes.drug-history":
        totalChecksTracker.current.totalDhChecks += 2;
        break;
      case "history-markschemes.obstetric-history":
        totalChecksTracker.current.totalObsthChecks += 2;
        break;
      case "history-markschemes.sexual-history":
        totalChecksTracker.current.totalSexhChecks += 2;
        break;
      case "history-markschemes.family-history":
        totalChecksTracker.current.totalFhChecks += 2;
        break;
      case "history-markschemes.social-history":
        totalChecksTracker.current.totalShChecks += 2;
        break;
      case "history-markschemes.systems-review":
        totalChecksTracker.current.totalSystemsChecks += 2;
        break;
      case "history-markschemes.ideas-concerns-expectations":
        totalChecksTracker.current.totalIceChecks += 2;
        break;
      case "all-markschemes.conclusion":
        totalChecksTracker.current.totalConcChecks += 2;
        break;
      case "all-markschemes.diagnosis":
        totalChecksTracker.current.totalDxChecks += 2;
        break;
      case "all-markschemes.investigations":
        totalChecksTracker.current.totalIxChecks += 2;
        break;
      case "all-markschemes.management":
        totalChecksTracker.current.totalTxChecks += 2;
        break;
      case "all-markschemes.questions":
        totalChecksTracker.current.totalQxChecks += 2;
        break;
      case "abcde-markscheme.danger":
        totalChecksTracker.current.totalDangerChecks += 2;
        break;
      case "abcde-markscheme.response":
        totalChecksTracker.current.totalResponseChecks += 2;
        break;
      case "abcde-markscheme.call_for_help":
        totalChecksTracker.current.totalCallHelpChecks += 2;
        break;
      case "abcde-markscheme.basic_observations":
        totalChecksTracker.current.totalBasicObsChecks += 2;
        break;
      case "abcde-markscheme.airway":
        totalChecksTracker.current.totalAirwayChecks += 2;
        break;
      case "abcde-markscheme.breathing":
        totalChecksTracker.current.totalBreathingChecks += 2;
        break;
      case "abcde-markscheme.circulation":
        totalChecksTracker.current.totalCirculationChecks += 2;
        break;
      case "abcde-markscheme.disability":
        totalChecksTracker.current.totalDisabilityChecks += 2;
        break;
      case "abcde-markscheme.exposure":
        totalChecksTracker.current.totalExposureChecks += 2;
        break;
      case "neurological-examination-markschemes.cn-i":
        totalChecksTracker.current.totalCN1Checks += 2;
        break;
      case "neurological-examination-markschemes.cn-ii":
        totalChecksTracker.current.totalCN2Checks += 2;
        break;
      case "neurological-examination-markschemes.cn-iii-iv-vi":
        totalChecksTracker.current.totalCN346Checks += 2;
        break;
      case "neurological-examination-markschemes.cn-v":
        totalChecksTracker.current.totalCN5Checks += 2;
        break;
      case "neurological-examination-markschemes.cn-vii":
        totalChecksTracker.current.totalCN7Checks += 2;
        break;
      case "neurological-examination-markschemes.cn-viii":
        totalChecksTracker.current.totalCN8Checks += 2;
        break;
      case "neurological-examination-markschemes.cn-ix-x-xii":
        totalChecksTracker.current.totalCN91012Checks += 2;
        break;
      case "neurological-examination-markschemes.cn-xi":
        totalChecksTracker.current.totalCN11Checks += 2;
        break;
      case "neurological-examination-markschemes.tone":
        totalChecksTracker.current.totalToneChecks += 2;
        break;
      case "neurological-examination-markschemes.power":
        totalChecksTracker.current.totalPowerChecks += 2;
        break;
      case "neurological-examination-markschemes.coordination":
        totalChecksTracker.current.totalCoordinationChecks += 2;
        break;
      case "neurological-examination-markschemes.reflexes":
        totalChecksTracker.current.totalReflexesChecks += 2;
        break;
      case "neurological-examination-markschemes.sensation":
        totalChecksTracker.current.totalSensationChecks += 2;
        break;
      case "neurological-examination-markschemes.special-tests":
        totalChecksTracker.current.totalSpecialTestsChecks += 2;
        break;
      case "examination-general-markschemes.inspection":
        totalChecksTracker.current.totalInspectionChecks += 2;
        break;
      case "examination-general-markschemes.hands":
        totalChecksTracker.current.totalHandsChecks += 2;
        break;
      case "examination-general-markschemes.face":
        totalChecksTracker.current.totalFaceChecks += 2;
        break;
      case "examination-general-markschemes.neck":
        totalChecksTracker.current.totalNeckChecks += 2;
        break;
      case "examination-general-markschemes.palpation":
        totalChecksTracker.current.totalPalpationChecks += 2;
        break;
      case "examination-general-markschemes.percussion":
        totalChecksTracker.current.totalPercussionChecks += 2;
        break;
      case "examination-general-markschemes.auscultation":
        totalChecksTracker.current.totalAuscultationChecks += 2;
        break;
      case "examination-general-markschemes.presentation":
        totalChecksTracker.current.totalPresentationChecks += 2;
        break;
      case "examination-general-markschemes.findings":
        totalChecksTracker.current.totalFindingsChecks += 2;
        break;
      case "neurological-examination-markschemes.gait":
        totalChecksTracker.current.totalGaitChecks += 2;
        break;
      case "examination-general-markschemes.function":
        totalChecksTracker.current.totalFunctionChecks += 2;
        break;
      case "examination-general-markschemes.ankles":
        totalChecksTracker.current.totalAnklesChecks += 2;
        break;
      case "examination-general-markschemes.chest":
        totalChecksTracker.current.totalChestChecks += 2;
        break;
      case "examination-general-markschemes.legs":
        totalChecksTracker.current.totalLegsChecks += 2;
        break;
      case "examination-general-markschemes.back":
        totalChecksTracker.current.totalBackChecks += 2;
        break;
      case "examination-general-markschemes.speculum":
        totalChecksTracker.current.totalSpeculumChecks += 2;
        break;
      case "examination-general-markschemes.groin":
        totalChecksTracker.current.totalGroinChecks += 2;
        break;
      case "examination-general-markschemes.abdomen":
        totalChecksTracker.current.totalAbdomenChecks += 2;
        break;
      case "explanation-markscheme.when":
        totalChecksTracker.current.totalWhenChecks += 2;
        break;
      case "explanation-markscheme.why":
        totalChecksTracker.current.totalWhyChecks += 2;
        break;
      case "explanation-markscheme.benefits":
        totalChecksTracker.current.totalBenefitsChecks += 2;
        break;
      case "explanation-markscheme.before":
        totalChecksTracker.current.totalBeforeChecks += 2;
        break;
      case "explanation-markscheme.how":
        totalChecksTracker.current.totalHowChecks += 2;
        break;
      case "explanation-markscheme.other":
        totalChecksTracker.current.totalOtherChecks += 2;
        break;
      case "explanation-markscheme.during":
        totalChecksTracker.current.totalDuringChecks += 2;
        break;
      case "explanation-markscheme.what":
        totalChecksTracker.current.totalWhatChecks += 2;
        break;
      case "explanation-markscheme.contraindications":
        totalChecksTracker.current.totalContraindicationsChecks += 2;
        break;
      case "explanation-markscheme.after":
        totalChecksTracker.current.totalAfterChecks += 2;
        break;
      case "explanation-markscheme.prognosis":
        totalChecksTracker.current.totalPrognosisChecks += 2;
        break;
      case "explanation-markscheme.risks":
        totalChecksTracker.current.totalRisksChecks += 2;
        break;
      case "explanation-markscheme.description":
        totalChecksTracker.current.totalDescriptionChecks += 2;
        break;
      default:
        break;
    }
  };
  const updateCheck = (amount) => {
    checks.current += amount;
  };
  const updateTotalCheck = () => {
    totalItems.current += 2;
  };

  useEffect(() => {
    const windowBig = window.innerWidth >= 1200;
    setSidebarOpen(windowBig);
  }, []);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  function handleInputChange(e) {
    e.persist();
    setPeerShortId(e.target.value.toUpperCase());
  }

  return (
    <>
      {!!internalLoading && <ThickLoadingWall />}
      <SEO
        title={`${data.strapiStations.Title} | OSCE Station`}
        description={`${data.strapiStations.Title} ${data.strapiStations.type} OSCE practise scenario for medical students. Practise ${data.strapiStations.Specialty} OSCE stations immediately`}
      />
      <Header
        title={data.strapiStations.Title}
        subheading={description}
        darkMode={true}
      />
      <Container class="w-container">
        {optionalText &&
          university == "University College London, University of London" && (
            <OptionalText>{optionalText}</OptionalText>
          )}
      </Container>
      <div class="section-browse wf-section">
        <div class="station-container w-container">
          <ActiveAccordion title="Candidate Instructions 🧒">
            <div class="paragraph">
              <>
                <Markdown>{candidate}</Markdown>
                <br />
                <Accordion
                  title="Station Material (ONLY OPEN WHEN PROMPTED)"
                  size="small"
                >
                  {additional_resources.length != 0 ? (
                    additional_resources.map((resource) => {
                      if (resource.media) {
                        if (resource.media.mime.includes("image/")) {
                          return (
                            <Accordion
                              title={resource.description}
                              size="small"
                            >
                              <ImageWrapper
                                src={resource.media.url}
                                onClick={() =>
                                  setLightbox(resource.description)
                                }
                              />
                              {lightbox == resource.description && (
                                <Lightbox
                                  mainSrc={resource.media.url}
                                  onCloseRequest={() => setLightbox("")}
                                />
                              )}
                            </Accordion>
                          );
                        } else if (resource.media.mime.includes("audio/")) {
                          return (
                            <Accordion
                              title={resource.description}
                              size="small"
                            >
                              <AudioPlayer src={resource.media.url} />
                            </Accordion>
                          );
                        }
                      } else {
                        console.log(resource);
                        const URL = resource.node.publicURL;
                        const name = resource.node.name;
                        return (
                          <PDFButton title={name} url={URL} size="small" />
                        );
                      }
                    })
                  ) : (
                    <>There are no station materials for this station</>
                  )}
                </Accordion>
              </>
            </div>
          </ActiveAccordion>
          <Accordion title="Actor Instructions 🤒">
            <div class="paragraph">
              {access ? (
                <ActorWrapper>
                  {Array.isArray(actorItems) ? (
                    actorItems.map((item) => {
                      if (item.type == "h3") {
                        return (
                          <>
                            <H3Wrapper>{item}</H3Wrapper>
                          </>
                        );
                      } else {
                        return <>{item}</>;
                      }
                    })
                  ) : (
                    <>{actorItems}</>
                  )}
                  {/* <Markdown>{actor}</Markdown> */}
                </ActorWrapper>
              ) : (
                <StationLock user={user} subscriberOnly={subscriber_only} />
              )}
            </div>
          </Accordion>

          <Accordion title="Mark Scheme ✍️">
            <div class="paragraph">
              {access ? (
                <ActorWrapper>
                  <SimpleAccordion
                    markscheme={markscheme}
                    additional_resources={additional_resources}
                    overriding_markscheme={overriding_markscheme}
                    checkboxClicked={checkboxClicked}
                    disabled={disabled}
                    updateSectionCheck={updateSectionCheck}
                    updateTotalSectionCheck={updateTotalSectionCheck}
                  />
                </ActorWrapper>
              ) : (
                <StationLock user={user} subscriberOnly={subscriber_only} />
              )}
            </div>
          </Accordion>

          <div class="results-div">
            {analysed ? (
              <>
                <ProgressbarWrapper>
                  <ProgressbarInnerWrapper>
                    <div class="total-score">Total Score</div>
                    <CircularProgressbar
                      value={
                        access ? (100 * delayedChecks) / totalItems.current : 0
                      }
                      text={
                        access
                          ? `${Math.round(
                              (delayedChecks / totalItems.current) * 100
                            )}%`
                          : "0%"
                      }
                      styles={buildStyles({
                        textColor: "#1e173f",
                        pathColor: "#1e173f",
                        trailColor: "#C4C2DA",
                      })}
                    />
                  </ProgressbarInnerWrapper>
                  {access && (
                    <Box
                      sx={{
                        display: "flex",
                        flexGrow: 1,
                        margin: "5px",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      {" "}
                      {totalChecks.map((object) => {
                        if (isNaN(Math.round(object.value))) {
                          return;
                        }
                        return (
                          <Linear title={object.title} value={object.value} />
                        );
                      })}
                    </Box>
                  )}
                </ProgressbarWrapper>
                {user && (
                  <div class="submit-results--div">
                    <div class="submit-score-div">
                      <div class="text-block-12">Submit Score</div>
                      <div class="text-block-14">
                        PERSONAL CODE:{" "}
                        <span class="text-span-14">{userShortId}</span>
                      </div>
                    </div>
                    <div class="text-block-13">
                      Track your results by saving your score or submitting them
                      to your OSCE peers.
                    </div>
                    {scoreSubmitSuccess == true ? (
                      <Alert severity="success">{scoreSubmitMessage}</Alert>
                    ) : (
                      <div class="form-block-3 w-form">
                        <form
                          id="wf-form-Personal-Code"
                          name="wf-form-Personal-Code"
                          data-name="Personal Code"
                          method="get"
                          class="form-3"
                        >
                          <input
                            type="text"
                            class="text-field-5 w-input"
                            maxlength="256"
                            name="name"
                            data-name="Name"
                            placeholder="YOUR PEER&#x27;S CODE"
                            id="name"
                            onChange={handleInputChange}
                            value={peerShortId}
                          />
                          <a
                            class="submit-button w-button"
                            onClick={submitPeerStation}
                          >
                            Submit Score to Peer
                          </a>
                          <div class="text-block-15">OR</div>
                          <a
                            class="submit-button white w-button"
                            onClick={submitStation}
                          >
                            Save to Personal Dashboard
                          </a>
                        </form>
                        <br />
                        {scoreSubmitSuccess == false && (
                          <Alert severity="error">{`Error - ${scoreSubmitMessage}`}</Alert>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </>
            ) : (
              // <Analysis
              //   access={access}
              //   delayedChecks={delayedChecks}
              //   totalItems={totalItems}
              //   totalChecks={totalChecks}
              //   userShortId={userShortId}
              //   handleInputChange={handleInputChange}
              //   peerShortId={peerShortId}
              //   submitPeerStation={submitPeerStation}
              //   submitStation={submitStation}
              // />
              <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => (
                  <AnalysisWrapper>
                    {tickedPoints.length == 0 ? (
                      <button
                        class="button w-button"
                        variant="contained"
                        {...bindTrigger(popupState)}
                      >
                        Analyse result 🔍
                      </button>
                    ) : (
                      <button
                        class="button w-button"
                        onClick={analyse}
                        variant="contained"
                      >
                        Analyse result 🔍
                      </button>
                    )}
                    <p>Convert your points into a realistic exam mark</p>
                    <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Typography sx={{ p: 2 }}>
                        Please mark the station first
                      </Typography>
                    </Popover>
                  </AnalysisWrapper>
                )}
              </PopupState>
              /* <a class="button w-button" onClick={analyse}>
                  Analyse result 🔍
                </a> */
            )}
          </div>
          <TableComponent stationAttempts={stationAttempts} />
        </div>
      </div>
      <SidebarObject isOpen={isSidebarOpen} toggle={toggleSidebar} />
      <Usps />
    </>
  );
};

export default StationTemplateNew;

export const query = graphql`
  query StationTemplate2($id: Int!, $idString: String!) {
    strapiStations(strapiId: { eq: $id }) {
      strapiId
      Title
      actor_instruction
      candidate_instruction
      description
      type
      Specialty
      subscriber_only
      example
      Optional_text
      markscheme {
        section_title
        points {
          point
        }
      }
      additional_resources {
        description
        media {
          url
          mime
        }
      }
      overriding_markscheme {
        id
        block {
          id
          points {
            point
            id
            feedback
            media {
              ext
              url
            }
          }
          Pass_threshold
          Excellent_threshold
          strapi_component
          title
        }
        station_title
      }
    }
    allFile(filter: { relativeDirectory: { eq: $idString } }) {
      edges {
        node {
          ext
          relativeDirectory
          absolutePath
          publicURL
          rdev
          url
          name
        }
      }
    }
  }
`;
