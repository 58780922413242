import React from "react";
import styled from "styled-components";
import { useTable } from "react-table";

import makeData from "./makeData";
import BasicModal from "../Modals/Breakdown";

const BreakdownButton = styled.button`
  background-color: #d7eccd;
`;

const Styles = styled.div`
  padding: 1rem;

  table {
    border-collapse: separate;
    border-spacing: 0 10px;
    width: 100%;
  }

  th {
    padding: 10px;
    background-color: #f2f2f2;
    font-weight: 400;
  }
  th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  th:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  td {
    text-align: center;
    padding: 10px;
    background-color: white;
  }
  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  td:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
`;

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function TableComponent({ stationAttempts }) {
  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "dateCreated", // accessor is the "key" in the data
      },
      {
        Header: "Submitted by",
        accessor: "submitter",
      },
      {
        Header: "Score",
        accessor: "percentage", // accessor is the "key" in the data
        Cell: ({ cell }) => {
          console.log(cell);
          return <BasicModal cell={cell} />;
        },
      },
      // {
      //   Header: "Save Result",
      //   accessor: "visits",
      // },
      // {
      //   Header: "Name",
      //   columns: [
      //     {
      //       Header: "First Name",
      //       accessor: "firstName",
      //     },
      //     {
      //       Header: "Last Name",
      //       accessor: "lastName",
      //     },
      //   ],
      // },
      // {
      //   Header: "Info",
      //   columns: [
      //     {
      //       Header: "Age",
      //       accessor: "age",
      //     },
      //     {
      //       Header: "Visits",
      //       accessor: "visits",
      //     },
      //     {
      //       Header: "Status",
      //       accessor: "status",
      //     },
      //     {
      //       Header: "Profile Progress",
      //       accessor: "progress",
      //     },
      //   ],
      // },
    ],
    []
  );
  const processStationAttempts = ({ stationAttempts }) => {
    function toDateTime(secs) {
      var t = new Date(1970, 0, 1); // Epoch
      t.setSeconds(secs);
      return t.toLocaleDateString();
    }
    console.log(stationAttempts);
    var processedAttempts = [];
    if (!!stationAttempts) {
      stationAttempts.map((stationAttempt) => {
        console.log(stationAttempt);
        console.log(toDateTime(stationAttempt.dateCreated._seconds));
        const processedAttempt = {
          dateCreated: toDateTime(stationAttempt.dateCreated._seconds),
          submitter: stationAttempt.submitter,
          percentage: stationAttempt.percentage,
          breakdown: stationAttempt.breakdown,
        };
        processedAttempts.push(processedAttempt);
      });
    }
    return processedAttempts;
  };
  // const data = React.useMemo(() => processStationAttempts({ stationAttempts }));
  const data = processStationAttempts({ stationAttempts });
  console.log(data);
  return (
    <>
      {stationAttempts.length > 0 && (
        <Styles>
          <div class="past-performance">Past Performance</div>
          <Table columns={columns} data={data} />
        </Styles>
      )}
    </>
  );
}

export default TableComponent;
