import React, { useState, useEffect } from "react";

function PDFButton({ children, title, size, url }) {
  const [setActive, setActiveState] = useState(false);

  function toggleAccordion() {
    setActiveState(setActive === false ? true : false);
  }
  return (
    <a
      class={
        size == "small"
          ? "osce-station-page-cards-small"
          : "osce-station-page-cards"
      }
      href={url}
      target="_blank"
    >
      <a
        class={
          size == "small" ? "heading-2-small pointer" : "heading-2 pointer"
        }
        href={url}
        target="_blank"
      >
        {title}
      </a>
      <div
        class={setActive ? "instructions-content-show" : "instructions-content"}
      >
        {children}
      </div>
    </a>
  );
}

export default PDFButton;
